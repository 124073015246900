.resources-container{
    margin:30px 0;
    overflow:auto
}
.resources-container:nth-child(1){
    margin-top:0
}
.resources-container .img-container{
    float:left;
    width:100%;
    max-width:265px
}
.resources-container .img-container img{
    width:100%;
    border-radius:10px
}
.resources-container .context{
    float:left;
    padding:15px 20px;
    width:calc(100% - 265px)
}
.resources-container .context h2.article-title{
    font-size:30px;
    margin-bottom:9px;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden
}

@media (max-width:425px){
    .resources-container .img-container{
        max-width:100%
    }
    .resources-container .context{
        padding:0
    }
}
@media (max-width:768px){
    .resources-container .context h2.article-title{
        font-size:25px;
        margin-bottom:2px
    }
}
@media (max-width:1024px){
    
    .resources-container .context{
        padding:0 10px
    }
}